import ChannelContext from '@jetshop/core/components/ChannelContext';
import { styled } from 'linaria/react';
import React, { useState, useRef, useEffect, useContext } from 'react';
import MobileCategories from './Categories/MobileCategories';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import FakeChannelSelector from './FakeChannelSelector';
import { DynamicContentRenderer } from '../../ContentEditor/DynamicContentRenderer';

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
`;

const SecondaryMenu = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  flex: 0 0 auto;
`;

const ContentWrapper = styled('div')`
  padding: 20px;
  z-index: 2;

  &.hide {
    display: none;
  }
`;

export default function MobileMenu({ data }) {
  const [locked, setLocked] = useState(false);
  const [openCat, setOpenCat] = useState(null);
  const menuWrapper = useRef(null);
  const { selectedChannel } = useContext(ChannelContext);
  const [showChannels, setShowChannels] = useState(false);

  useEffect(() => {
    if (showChannels) {
      document
        .getElementById('last-channel')
        .scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [showChannels]);

  if (!(data && data.categories)) return null;
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen}>
          <Scroll>
            <MobileCategories
              menuWrapper={menuWrapper}
              locked={locked}
              setLocked={setLocked}
              categories={data.categories}
              closeMenu={drawer.hideTarget}
              openCat={openCat}
              setOpenCat={setOpenCat}
            />

            <SecondaryMenu>
              {selectedChannel.id !== 7 && (
                <FakeChannelSelector
                  initialOpen={true}
                  hideMenu={drawer.hideTarget}
                  setShowChannels={setShowChannels}
                />
              )}
            </SecondaryMenu>
          </Scroll>
          <ContentWrapper className={openCat !== 161 && 'hide'}>
            <DynamicContentRenderer categoryId={2069} />
          </ContentWrapper>
        </Drawer>
      )}
    </DrawerTarget>
  );
}
