import { theme } from '../../../Theme';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React, { useState, useRef, useEffect } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import getCategoriesByLevel from './__util__/getCategoriesByLevel';
import getCategoriesUnderParent from './__util__/getCategoriesUnderParent';
import { ReactComponent as AngleLeft } from '../../../../svg/AngleLeft.svg';
import { ReactComponent as AngleRight } from '../../../../svg/AngleRight.svg';
import { ReactComponent as Times } from '../../../../svg/Times.svg';

const handleClickAndPreventDefault = (e, handleClick, i) => {
  e.preventDefault();
  handleClick(i);
};

const Icon = styled('div')`
  svg {
    width: 10px;
  }
`;

export const CloseButton = styled('button')`
  background: none;
  border: 0;
  appearance: none;
  border-radius: 0;
  padding: 1rem;
  svg {
    display: block;
    width: 20px !important;
    height: 20px;
  }
  path {
    fill: black;
  }
`;

const Wrapper = styled('div')`
  &.toplvl {
    background: white;
    a {
      background: white;
    }
  }
  a {
    border-top: 1px solid #e6e6e6;
    display: block;
    padding: 1rem;
    position: relative;
    color: #333333;
    text-decoration: none;
    font-weight: normal;
    font-size: 14px;
    background: white;
    font-family: ${theme.fonts.primary};
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 700;

    &.allbtn {
      font-weight: 600;
    }
    svg {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:last-child a {
    border-bottom: 1px solid #e6e6e6;
  }
  .non-clickable {
    pointer-events: none;
  }
`;

const SubcategoryWrapper = styled('div')`
  /* background: #f7f7f7; */
  background-color: #fff;
  position: absolute;
  z-index: 2;
  top: 1px;
  left: 0;
  transform: translateX(-100%);
  width: 100%;
  height: calc(100% - 1px);
  /* min-height: 150vh;
  max-height: 100vh; */
  &.open {
    transform: translateX(0);
    opacity: 1;
    /* max-height: 9999px; */
    bottom: auto;
    padding-bottom: 100px;
  }
`;

const SubNavHeader = styled('header')`
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 50px;
`;

const BackButton = styled('button')`
  background: none;
  padding: 1rem;
  &:focus {
    outline: none;
  }
  font-size: 1rem;
  path {
    fill: black;
  }
`;

const CategoryTitle = styled('span')`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: ${theme.fonts.primary};
  letter-spacing: 0.5px;
`;

const MobileCategories = ({
  categories,
  closeMenu,
  parentCategory = null,
  menuWrapper,
  setLocked,
  openCat,
  setOpenCat
}) => {
  const thisSubMenu = useRef(null);
  const [openIndex, setOpenIndex] = useState(null);
  const categoryLevel = parentCategory
    ? [parentCategory]
    : getCategoriesByLevel(categories, 2);

  useEffect(
    v => {
      if (thisSubMenu.current && thisSubMenu.current.scrollHeight) {
        setLocked(
          thisSubMenu.current.scrollHeight < menuWrapper.current.scrollHeight
        );
      } else {
        setLocked(false);
      }
      if (openCat === null) {
        setOpenIndex(null);
      }
    },
    [openCat, menuWrapper, setLocked]
  );

  return (
    <>
      {categoryLevel.map((category, i) => (
        <Wrapper
          className={`${!parentCategory ? 'toplvl' : 'sublvl'} `}
          key={i}
        >
          <CategoryLink
            className={`${!parentCategory ? 'toplvl' : 'sublvl'}`}
            onClick={e => {
              console.log(category);
              if (category.hasSubcategories) {
                e.preventDefault();
                handleClickAndPreventDefault(
                  e,
                  () => {
                    setOpenCat(category.id);
                    setOpenIndex(i);
                  },
                  i
                );
              } else {
                closeMenu();
              }
            }}
            category={category}
          >
            {category.mainHeader}
            {category.hasSubcategories && (
              <Icon>
                <AngleRight />
              </Icon>
            )}
          </CategoryLink>

          <SubcategoryWrapper
            className={openIndex === i ? 'open' : null}
            innerRef={openCat === category.id ? thisSubMenu : null}
          >
            <SubNavHeader>
              <BackButton
                onClick={() => {
                  setOpenCat(category.parentId);
                  setOpenIndex(category.parentId);
                  setLocked(false);
                }}
              >
                <Icon>
                  <AngleLeft />
                </Icon>
              </BackButton>
              <CategoryTitle>{category.mainHeader}</CategoryTitle>
              <CloseButton
                style={{ visibility: 'hidden' }}
                className="close-btn"
                onClick={() => {
                  closeMenu();
                  setOpenCat(null);
                }}
              >
                <Icon>
                  <Times />
                </Icon>
              </CloseButton>
            </SubNavHeader>
            <Wrapper>
              <CategoryLink
                onClick={() => {
                  closeMenu();
                  setOpenCat(null);
                }}
                className={'allbtn sublvl'}
                category={category}
              >
                {t('See all {category.mainHeader}', { category })}
              </CategoryLink>
            </Wrapper>
            <SubCategories
              setLocked={setLocked}
              menuWrapper={menuWrapper}
              categories={categories}
              category={category}
              closeMenu={closeMenu}
              setOpenCat={setOpenCat}
              openCat={openCat}
            />
          </SubcategoryWrapper>
        </Wrapper>
      ))}
    </>
  );
};

const SubCategories = ({
  categories,
  category,
  closeMenu,
  menuWrapper,
  setLocked,
  setOpenCat,
  openCat
}) => {
  return getCategoriesUnderParent(categories, category.id).map(
    (childCat, index) => {
      return category.hasSubcategories ? (
        <MobileCategories
          setLocked={setLocked}
          menuWrapper={menuWrapper}
          key={index}
          categories={categories}
          closeMenu={closeMenu}
          parentCategory={childCat}
          setOpenCat={setOpenCat}
          openCat={openCat}
        />
      ) : (
        <>
          {childCat.name === 'Menybild' ? null : (
            <CategoryLink
              className="sublvl"
              key={index}
              onClick={() => {
                closeMenu();
                setOpenCat(null);
              }}
              category={childCat}
            >
              {childCat.images?.length > 0 && (
                <img
                  src={childCat.images[0].url}
                  alt={childCat.mainHeader}
                  style={{ marginRight: '8px', width: '25px', height: 'auto' }}
                />
              )}
              {childCat.mainHeader}
            </CategoryLink>
          )}
        </>
      );
    }
  );
};

export default MobileCategories;
